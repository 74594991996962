export function friendlyTimeFromMinutes(minutes: string) {
  const hours = Math.floor(parseInt(minutes) / 60);
  const remainingMinutes = parseInt(minutes) % 60;

  if (hours > 0 && remainingMinutes > 0) {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'}, ${remainingMinutes} ${
      remainingMinutes === 1 ? 'minute' : 'minutes'
    }`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'}, 0 minutes`;
  } else if (remainingMinutes > 0) {
    return `${remainingMinutes} ${
      remainingMinutes === 1 ? 'minute' : 'minutes'
    }`;
  } else {
    return 'None';
  }
}

export function shortFriendlyTimeFromMinutes(minutes: string) {
  const hours = Math.floor(parseInt(minutes) / 60);
  const remainingMinutes = parseInt(minutes) % 60;

  if (hours > 0) {
    return `${hours} hr ${remainingMinutes} min`;
  } else if (remainingMinutes > 0) {
    return `${remainingMinutes} min`;
  } else {
    return 'None';
  }
}

export function shortFriendlyTimeFromMinutesOrUntracked(minutes: string) {
  const hours = Math.floor(parseInt(minutes) / 60);
  const remainingMinutes = parseInt(minutes) % 60;

  if (hours > 0) {
    return `${hours} hr ${remainingMinutes} min`;
  } else if (remainingMinutes > 0) {
    return `${remainingMinutes} min`;
  } else {
    return 'None or untracked';
  }
}
