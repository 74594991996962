import { proxyHeader } from './proxyHeader';

export enum LogType {
  ERROR = 'ERROR',
  INFO = 'INFO',
}

const server = process.env.REACT_APP_SS_EXPRESS_URL;

export const log = (message: any, type: LogType) => {
  if (type === LogType.ERROR) console.error(message);
  else console.log(message);

  fetch(`${server}/react-logger`, {
    method: 'POST',
    body: JSON.stringify({
      type: type,
      message: `(react ticket-time-tracker): ${message}`,
    }),
    headers: {
      'Content-Type': 'application/json',
      ...proxyHeader,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error('Error:', error);
    });
};
