import { useState, useEffect } from 'react';

import { proxyHeader } from './lib/proxyHeader';
import { LogType, log } from './lib/logger';
import {
  shortFriendlyTimeFromMinutes,
  shortFriendlyTimeFromMinutesOrUntracked,
} from './lib/utils';

interface WindowWithUserid extends Window {
  ss_userid: string;
}

const server = process.env.REACT_APP_SS_EXPRESS_URL;

function extractTidFromCurrentUrl(): string | null {
  /// If this is the viewticket.php pagea and there is a tid in the URL, then return it
  const url = window.location.href;
  const urlObj = new URL(url);
  if (urlObj.pathname.includes('/viewticket.php')) {
    const searchParams = urlObj.searchParams;
    const tid = searchParams.get('tid');
    return tid !== null ? tid : null;
  }
  return null;
}

function ClientSidebar() {
  const [isTimeTrackingVisible, setIsTimeTrackingVisible] = useState(false);
  const [totalTimeThisMonth, setTotalTimeThisMonth] = useState(0);
  const [totalTimeLastMonth, setTotalTimeLastMonth] = useState(0);
  const [selectedTicketTimeThisMonth, setSelectedTicketTimeThisMonth] =
    useState(0);
  const [
    selectedTicketTimePreviousMonths,
    setSelectedTicketTimePreviousMonths,
  ] = useState(0);
  const [currentTicketTid, setCurrentTicketTid] = useState<String | null>(null);

  useEffect(() => {
    const userid = (window as unknown as WindowWithUserid).ss_userid;

    const displayOnURL = () => {
      /*
       *  We only display this when one of these URLs is being accessed.
       * Mainly the main clientarea.php home, the view invoice page, and the
       * pages related to tickets.
       */

      const { pathname, search } = window.location;

      if (pathname.includes('ticket')) {
        return true;
      }

      if (search.includes('action=invoices')) {
        return true;
      }

      if (pathname === '/clientarea.php' && search === '') {
        return true;
      }

      return false;
    };

    const fetchTimeTrackedStatus = async () => {
      try {
        const response = await fetch(
          `${server}/customers/can-see-time-tracking-data?userid=${userid}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              ...proxyHeader,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setIsTimeTrackingVisible(data.isTimeTrackingVisible);
        }
      } catch (error) {
        console.error('Error fetching time tracked status:', error);
      }
    };

    // Only display this sidebar panel for certain URLs
    if (displayOnURL()) {
      fetchTimeTrackedStatus();
    }
  }, []);

  useEffect(() => {
    // If this is the /viewticket.php page fetch currently viewed ticket time
    if (!isTimeTrackingVisible) {
      return;
    }

    const tid = extractTidFromCurrentUrl();
    if (!tid) return;

    setCurrentTicketTid(tid);

    const userid = (window as unknown as WindowWithUserid).ss_userid;
    if (!userid) {
      log('Failed to obtain ss_userid from page.', LogType.ERROR);
      return;
    }

    const fetchTickets = async () => {
      try {
        const response = await fetch(
          `${server}/customers/single-ticket-time?userid=${userid}&tid=${tid}`,
          {
            headers: {
              ...proxyHeader,
            },
          }
        );
        const data = await response.json();
        setSelectedTicketTimeThisMonth(data.minutes_this_month);
        if (data.minutes_total > data.minutes_this_month) {
          setSelectedTicketTimePreviousMonths(
            data.minutes_total - data.minutes_this_month
          );
        }
      } catch (error) {
        console.error('Error fetching ticket data:', error);
      }
    };
    fetchTickets();
  }, [isTimeTrackingVisible]);

  useEffect(() => {
    if (!isTimeTrackingVisible) {
      return;
    }

    const userid = (window as unknown as WindowWithUserid).ss_userid;
    if (!userid) {
      log('Failed to obtain ss_userid from page.', LogType.ERROR);
      return;
    }

    const fetchTickets = async () => {
      try {
        const response = await fetch(
          `${server}/customers/monthly-ticket-time?userid=${userid}`,
          {
            headers: {
              ...proxyHeader,
            },
          }
        );
        const data = await response.json();
        setTotalTimeThisMonth(data.minutes_this_month);
        setTotalTimeLastMonth(data.minutes_last_month);
      } catch (error) {
        console.error('Error fetching ticket data:', error);
      }
    };
    fetchTickets();
  }, [isTimeTrackingVisible]);

  if (!isTimeTrackingVisible) {
    return null;
  }

  return (
    <div className="mb-3 card card-sidebar">
      <div className="card-header">
        <h3 className="card-title m-0">
          <i className="far fa-hourglass-half"></i>&nbsp; Support Time Used
          <i className="fas fa-chevron-up card-minimise float-right"></i>
        </h3>
      </div>
      <div className="collapsable-card-body">
        <div className="card-body">
          {currentTicketTid && (
            <>
              <span style={{ fontSize: '14.4px' }}>
                <i>On Current Ticket</i> <br />
              </span>
            </>
          )}
          {currentTicketTid && (
            <div>
              <span className="title">This month - </span>{' '}
              {shortFriendlyTimeFromMinutes(
                selectedTicketTimeThisMonth.toString()
              )}
              <br />
            </div>
          )}
          {currentTicketTid && (
            <div>
              <span className="title">Past months - </span>{' '}
              {shortFriendlyTimeFromMinutesOrUntracked(
                selectedTicketTimePreviousMonths.toString()
              )}
              <br />
            </div>
          )}
          {currentTicketTid && <br />}
          <span style={{ fontSize: '14.4px' }}>
            {currentTicketTid && (
              <span style={{ fontSize: '14.4px' }}>
                <i>On All Tickets</i>
                <br />
              </span>
            )}
            <span className="title">This month - </span>
            {shortFriendlyTimeFromMinutes(totalTimeThisMonth.toString())}
            <br />

            {totalTimeLastMonth > 0 && (
              <div>
                <span className="title">Last month - </span>{' '}
                {shortFriendlyTimeFromMinutes(totalTimeLastMonth.toString())}
                <br />
              </div>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ClientSidebar;
