import { useState, useEffect } from 'react';

import { log, LogType } from './lib/logger';
import { proxyHeader } from './lib/proxyHeader';
import { shortFriendlyTimeFromMinutes } from './lib/utils';

interface WindowWithUserid extends Window {
  ss_userid: string;
}

interface Ticket {
  tid: string;
  timeLoggedCurrentMonth: number;
  timeLoggedAllTime: number;
}

const server = process.env.REACT_APP_SS_EXPRESS_URL;

const getTidFromAnchorTag = (anchor: string): string | null => {
  const regex = /tid=([\w-]+)/;
  const match = anchor.match(regex);

  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
};

function ClientListTickets() {
  const [tickets, setTickets] = useState<Ticket[]>([]);

  const [isTimeTrackingVisible, setIsTimeTrackingVisible] = useState(false);

  useEffect(() => {
    if (!isTimeTrackingVisible) return;

    const addFifthColumn = () => {
      const table = document.getElementById('tableTicketsList');
      if (!table) return;

      const rows = table.getElementsByTagName('tr');
      if (!rows) return;

      const columnCount = rows[0].getElementsByTagName('th').length;
      /*
      There are initially 4 columns in the table, and this function adds a fifth one for the ticket time.  If there are 5 rows already, then we just return
      */
      if (columnCount === 5) {
        return;
      }

      // Create the new th element for the fifth column
      const newTh = document.createElement('th');
      newTh.style.width = '15%';
      newTh.textContent = 'Time this month';

      // Insert the new th as the fifth child of the first row
      rows[0].insertBefore(newTh, rows[0].children[4]);
    };

    addFifthColumn();
  }, [isTimeTrackingVisible]);

  useEffect(() => {
    const userid = (window as unknown as WindowWithUserid).ss_userid;

    const fetchTimeTrackedStatus = async () => {
      try {
        const response = await fetch(
          `${server}/customers/can-see-time-tracking-data?userid=${userid}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              ...proxyHeader,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setIsTimeTrackingVisible(data.isTimeTrackingVisible);
        }
      } catch (error) {
        console.error('Error fetching time tracked status:', error);
      }
    };

    fetchTimeTrackedStatus();
  }, []);

  useEffect(() => {
    if (!isTimeTrackingVisible) return;

    const userid = (window as unknown as WindowWithUserid).ss_userid;
    if (!userid) {
      log('Failed to obtain ss_userid from page.', LogType.ERROR);
      return;
    }

    const fetchTickets = async () => {
      try {
        const response = await fetch(
          `${server}/customers/support-time-all-tickets?userid=${userid}`,
          {
            headers: {
              ...proxyHeader,
            },
          }
        );
        const data = await response.json();
        setTickets(data);
      } catch (error) {
        console.error('Error fetching ticket data:', error);
      }
    };
    fetchTickets();
  }, [isTimeTrackingVisible]);

  useEffect(() => {
    if (!isTimeTrackingVisible) return;
    const getTicket = (targetTid: string) => {
      const ticket = tickets.find((ticket) => ticket.tid === targetTid);
      return ticket;
    };

    function handleSortOrderChange(
      observer: MutationObserver,
      observedTable: HTMLElement,
      config: MutationObserverInit
    ) {
      // Stop the observer from observing the table temporarily
      observer.disconnect();

      const table = document.getElementById('tableTicketsList');
      if (!table) return;

      const rows = table.getElementsByTagName('tr');
      if (!rows) return;

      for (let i = 1; i < rows.length; i++) {
        const row = rows[i];

        // We obtain the id of the time tracking cell from the anchor tag in the second column
        const secondTd = row.children[1];
        const anchorTag = secondTd.querySelector('a');
        if (!anchorTag) continue;

        const ticketId = getTidFromAnchorTag(anchorTag.toString() as string);
        if (!ticketId) continue;

        let td = row.querySelector(`td#${ticketId}`) as HTMLTableCellElement;

        if (!td) {
          // Create it anew
          td = document.createElement('td');
          td.id = ticketId;
          td.style.textAlign = 'center';
          row.insertBefore(td, row.children[4]);
        }

        // Get the ticket from the tickets array
        const ticket = getTicket(ticketId);
        if (ticket) {
          td.textContent = shortFriendlyTimeFromMinutes(
            ticket.timeLoggedCurrentMonth.toString()
          );
        } else {
          td.textContent = 'None';
        }
      }
      observer.observe(observedTable, config);
    }

    const table = document.getElementById('tableTicketsList');
    if (!table) return;
    // Create a new MutationObserver instance
    const observer = new MutationObserver((mutationsList) => {
      // Handle mutations
      for (const mutation of mutationsList) {
        // Check if the table structure has changed
        if (mutation.type === 'childList') {
          handleSortOrderChange(observer, table, config);
        }
      }
    });

    // Configuration for the observer
    const config = {
      childList: true,
      subtree: true,
    };

    // Start observing the table for changes
    handleSortOrderChange(observer, table, config);
    observer.observe(table, config);
    return () => {
      observer.disconnect();
    };
  }, [tickets, isTimeTrackingVisible]);

  return null;
}

export default ClientListTickets;
