import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ClientListTickets from './ClientListTickets';
import ClientSidebar from './ClientSidebar';

const clientSidebarId = 'ssttt-client-sidebar';
const clientSidebarElement = document.getElementById(
  clientSidebarId
) as HTMLElement;

const ticketResponseAppElementId = 'ssttt-ticket-reply';
const ticketResponseAppElement = document.getElementById(
  ticketResponseAppElementId
) as HTMLElement;

const ticketNoteAppElementId = 'ssttt-ticket-note';
const ticketNoteAppElement = document.getElementById(
  ticketNoteAppElementId
) as HTMLElement;

const clientListTicketsAppElementId = 'ssttt-client-list-tickets';
const clientListTicketsAppElement = document.getElementById(
  clientListTicketsAppElementId
) as HTMLElement;

if (clientSidebarElement) {
  const clientSidebarRoot = ReactDOM.createRoot(clientSidebarElement);
  clientSidebarRoot.render(
    <React.StrictMode>
      <ClientSidebar />
    </React.StrictMode>
  );
}

if (clientListTicketsAppElement) {
  const clientListTicketsRoot = ReactDOM.createRoot(
    clientListTicketsAppElement
  );
  clientListTicketsRoot.render(
    <React.StrictMode>
      <ClientListTickets />
    </React.StrictMode>
  );
}

if (ticketResponseAppElement) {
  const ticketResponseRoot = ReactDOM.createRoot(ticketResponseAppElement);
  ticketResponseRoot.render(
    <React.StrictMode>
      <App rootElementId={ticketResponseAppElementId} />
    </React.StrictMode>
  );
}

if (ticketNoteAppElement) {
  const ticketNoteRoot = ReactDOM.createRoot(ticketNoteAppElement);
  ticketNoteRoot.render(
    <React.StrictMode>
      <App rootElementId={ticketNoteAppElementId} />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
