import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';

import { LogType, log } from './lib/logger';
import { proxyHeader } from './lib/proxyHeader';
import { friendlyTimeFromMinutes } from './lib/utils';

interface AppProps {
  rootElementId: string;
}

interface WindowWithAdminId extends Window {
  ss_adminid: string;
}

const server = process.env.REACT_APP_SS_EXPRESS_URL;

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App({ rootElementId }: AppProps) {
  const [enableTicketTime, setEnableTicketTime] = useState(false);
  const [isTimeButtonDisabled, setIsTimeButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState('');

  const [ticketTotalMinutes, setTicketTotalMinutes] = useState('');
  const [ticketTotalMinutesByAdmin, setTicketTotalMinutesByAdmin] =
    useState('');
  const [totalCustomerMinutesThisMonth, setTotalCustomerMinutesThisMonth] =
    useState('');

  const [selectedHours, setSelectedHours] = React.useState('0');
  const [selectedMinutes, setSelectedMinutes] = React.useState('');
  const [comment, setComment] = React.useState('');

  const [error, setError] = React.useState('');
  const [warning, setWarning] = React.useState('');
  const [success, setSuccess] = React.useState('');

  const handleSuccessClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccess('');
  };

  const handleErrorClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setError('');
  };

  const handleWarningClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setWarning('');
  };

  const handleHoursChange = (event: SelectChangeEvent) => {
    setSelectedHours(event.target.value);
  };

  const handleMinutesChange = (event: SelectChangeEvent) => {
    setSelectedMinutes(event.target.value);
  };

  const handleCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleSubmit = useCallback(() => {
    let minutesOnTicket = 0;
    if (selectedHours === '0' && selectedMinutes === '') {
      setError('Please first select the time you worked on the ticket.');
      return false;
    }

    const mins = selectedMinutes === '' ? 0 : parseInt(selectedMinutes);
    minutesOnTicket = parseInt(selectedHours) * 60 + mins;

    const adminid = (window as unknown as WindowWithAdminId).ss_adminid;
    if (!adminid) {
      setError('Ticket Tracker Error: Failed to obtain ss_adminid from page.');
      log('Failed to obtain ss_adminid from page.', LogType.ERROR);
      return false;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const tid = searchParams.get('id');
    if (!tid) {
      setError('Ticket Tracker Error: Failed to obtain ticket id from url.');
      log('Failed to obtain ticket id from url.', LogType.ERROR);
      return false;
    }

    let timeType: string;

    switch (rootElementId) {
      case 'ssttt-ticket-reply':
        timeType = 'REPLY';
        break;
      case 'ssttt-ticket-note':
        timeType = 'NOTE';
        break;
      default:
        setError('Ticket Tracker Error: Unknown root element id.');
        return false;
    }

    fetch(`${server}/ticket-time-tracker`, {
      method: 'POST',
      body: JSON.stringify({
        tid: tid,
        type: timeType,
        adminid: adminid,
        comment: comment,
        minutes_worked: minutesOnTicket,
      }),
      headers: {
        'Content-Type': 'application/json',
        ...proxyHeader,
      },
    }).finally(() => {
      let formId;
      if (rootElementId === 'ssttt-ticket-reply') {
        formId = 'frmAddTicketReply';
      } else if (rootElementId === 'ssttt-ticket-note') {
        formId = 'frmAddTicketNote';
      }
      if (!formId) {
        setError('Ticket Tracker Error: Unknown root element id.');
        return false;
      }

      // Determine if this is the reply form or the note form, then re-enable the submit button
      let buttonId;
      switch (rootElementId) {
        case 'ssttt-ticket-reply':
          buttonId = 'btnPostReply';
          break;
        case 'ssttt-ticket-note':
          buttonId = 'btnAddNote';
          break;
        default:
          setError('Ticket Tracker Error: Unknown root element id.');
          return;
      }

      const button = document.getElementById(buttonId) as HTMLButtonElement;
      if (button) {
        button.click();
      }
      setSuccess(
        `${friendlyTimeFromMinutes(
          minutesOnTicket.toString()
        )} added to ticket.`
      );
      setIsTimeButtonDisabled(true);
    });
  }, [selectedHours, selectedMinutes, rootElementId, comment]);

  // Should we track time for this customer?
  useEffect(() => {
    const adminid = (window as unknown as WindowWithAdminId).ss_adminid;
    if (!adminid) {
      setError('Ticket Tracker Error: Failed to obtain ss_adminid from page.');
      log('Failed to obtain ss_adminid from page.', LogType.ERROR);
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const tid = searchParams.get('id');
    let buttonId: string;
    if (!tid) {
      setError('Ticket Tracker Error: Failed to obtain ticket id from ticket.');
      log('Failed to obtain ticket id from ticket.', LogType.ERROR);
      return;
    }

    const fetchData = async () => {
      try {
        await fetch(
          `${server}/ticket-time-tracker/?tid=${tid}&adminid=${adminid}`,
          {
            headers: {
              ...proxyHeader,
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              log(
                `HTTP error! status: ${response.status} statusText: ${response.statusText}`,
                LogType.ERROR
              );
            }
            return response;
          })
          .then((response) => response.json()) // parse JSON from request
          .then((response) => {
            setEnableTicketTime(response.enable_ticket_time);
            setTicketTotalMinutes(
              friendlyTimeFromMinutes(response.ticket_total_minutes)
            );
            setTicketTotalMinutesByAdmin(
              friendlyTimeFromMinutes(response.ticket_total_minutes_by_admin)
            );
            setTotalCustomerMinutesThisMonth(
              friendlyTimeFromMinutes(
                response.total_customer_minutes_this_month
              )
            );

            const secondsSinceLastPost = response.seconds_since_last_post;
            const minutesAddedLastPost = response.minutes_added_last_post;
            if (
              secondsSinceLastPost &&
              minutesAddedLastPost &&
              secondsSinceLastPost < 31 &&
              minutesAddedLastPost > 0
            ) {
              const friendlyTime =
                friendlyTimeFromMinutes(minutesAddedLastPost);
              setWarning(
                `WARNING: You added ${friendlyTime} of time to this ticket ${secondsSinceLastPost} seconds ago. If you refreshed this page due to a WHMCS session expiration, please be careful not to post that time again. For instance, just add 0 minutes.`
              );
            }

            // Determine if this is the reply form or the note form, then disable the submit button
            switch (rootElementId) {
              case 'ssttt-ticket-reply':
                buttonId = 'btnPostReply';
                setButtonText('Add Time & Reply');
                break;
              case 'ssttt-ticket-note':
                buttonId = 'btnAddNote';
                setButtonText('Add Time & Note');
                break;
              default:
                setError('Ticket Tracker Error: Unknown root element id.');
                return;
            }

            if (response?.enable_ticket_time) {
              const button = document.getElementById(
                buttonId
              ) as HTMLButtonElement;
              if (button) {
                button.style.display = 'none';
              }
            }
          });
      } catch (error: any) {
        setError('Ticket Tracker Error: ' + error);
        log(
          `Error in useEffect that checks if customer ticket time is tracked: ${error}`,
          LogType.ERROR
        );
      }
    };

    fetchData();
  }, [rootElementId]);

  if (!enableTicketTime) {
    return (
      <div>
        <Snackbar
          open={error !== ''}
          onClose={handleErrorClose}
          autoHideDuration={30000}
        >
          <Alert
            onClose={handleErrorClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            <Typography variant="h5" component="div">
              {error}
            </Typography>
          </Alert>
        </Snackbar>
      </div>
    );
  }

  return (
    <div>
      <Box sx={{ flexGrow: 1, p: 2, border: '0px', padding: '0px' }}>
        <Paper>
          <Grid container spacing={1} alignItems="center" padding={'10px'}>
            <Grid xs={1}>
              <div>
                <Typography variant="body1" component="div">
                  Add time:
                </Typography>
              </div>
            </Grid>
            <Grid xs={2}>
              <div>
                <FormControl sx={{ m: 1, p: 0, margin: 0 }} size="medium">
                  <Select
                    value={selectedHours}
                    onChange={handleHoursChange}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value={0}>0 Hours</MenuItem>
                    <MenuItem value={1}>1 Hour</MenuItem>
                    <MenuItem value={2}>2 Hours</MenuItem>
                    <MenuItem value={3}>3 Hours</MenuItem>
                    <MenuItem value={4}>4 Hours</MenuItem>
                    <MenuItem value={5}>5 Hours</MenuItem>
                    <MenuItem value={6}>6 Hours</MenuItem>
                    <MenuItem value={7}>7 Hours</MenuItem>
                    <MenuItem value={8}>8 Hours</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid xs={2}>
              <div>
                <FormControl sx={{ m: 1, padding: 0, margin: 0 }} size="medium">
                  <Select
                    value={selectedMinutes}
                    onChange={handleMinutesChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="">Minutes</MenuItem>
                    <MenuItem value={0}>0 Minutes</MenuItem>
                    <MenuItem value={2}>2 Minutes</MenuItem>
                    <MenuItem value={5}>5 Minutes</MenuItem>
                    <MenuItem value={10}>10 Minutes</MenuItem>
                    <MenuItem value={15}>15 Minutes</MenuItem>
                    <MenuItem value={20}>20 Minutes</MenuItem>
                    <MenuItem value={25}>25 Minutes</MenuItem>
                    <MenuItem value={30}>30 Minutes</MenuItem>
                    <MenuItem value={35}>35 Minutes</MenuItem>
                    <MenuItem value={40}>40 Minutes</MenuItem>
                    <MenuItem value={45}>45 Minutes</MenuItem>
                    <MenuItem value={50}>50 Minutes</MenuItem>
                    <MenuItem value={55}>55 Minutes</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid xs={7}>
              <div>
                <TextField
                  id="outlined-multiline-flexible"
                  onChange={handleCommentChange}
                  value={comment}
                  label="Optional Comment"
                  multiline
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    style: {
                      marginLeft: '0px',
                      fontFamily:
                        '-apple-system, Roboto, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif',
                      fontSize: '16px',
                    },
                  }}
                />
              </div>
            </Grid>
            <Grid xs={3}>
              <div>
                <Typography variant="body1" component="div">
                  Total time this ticket: {ticketTotalMinutes}
                </Typography>
              </div>
            </Grid>
            <Grid xs={3}>
              <div>
                <Typography variant="body1" component="div">
                  Your time this ticket: {ticketTotalMinutesByAdmin}
                </Typography>
              </div>
            </Grid>
            <Grid xs={3}>
              <div>
                <Typography variant="body1" component="div">
                  Customer time this month: {totalCustomerMinutesThisMonth}
                </Typography>
              </div>
            </Grid>
            <Grid xs={3}>
              <div>
                <Typography variant="body1" component="div">
                  <Button
                    variant="contained"
                    size="large"
                    style={{ fontSize: '12px', padding: '10px' }}
                    onClick={handleSubmit}
                    disabled={isTimeButtonDisabled}
                  >
                    {buttonText}
                  </Button>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Snackbar
        open={success !== ''}
        onClose={handleSuccessClose}
        autoHideDuration={5000}
      >
        <Alert
          onClose={handleSuccessClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          <Typography variant="h5" component="div">
            {success}
          </Typography>
        </Alert>
      </Snackbar>
      <Snackbar
        open={warning !== ''}
        onClose={handleWarningClose}
        autoHideDuration={12000}
      >
        <Alert
          onClose={handleWarningClose}
          severity="warning"
          sx={{ width: '100%' }}
        >
          <Typography variant="h5" component="div">
            {warning}
          </Typography>
        </Alert>
      </Snackbar>
      <Snackbar
        open={error !== ''}
        onClose={handleErrorClose}
        autoHideDuration={8000}
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          <Typography variant="h5" component="div">
            {error}
          </Typography>
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
